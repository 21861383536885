<template>
    <div class="custom-item">
        <div class="row">
            <div :class="{'col-sm-6': image}">
                <img :src="image" class="img-responsive" v-if="image">
            </div>

            <div :class="{'col-sm-6': image, 'col-sm-12': !image}">
                <div>
                    <strong v-if="headline1">{{headline1}}</strong>

                    <p v-if="description1">{{description1}}</p>
                </div>

                <div>
                    <strong v-if="headline2">{{headline2}}</strong>

                    <p v-if="description2">{{description2}}</p>
                </div>

                <div>
                    <strong v-if="headline3">{{headline3}}</strong>

                    <p v-if="description3">{{description3}}</p>
                </div>

                <a :href="linkUrl" target="_blank" class="btn btn-primary btn-fill" rel="noreferrer noopener" v-if="linkUrl && linkText">
                    {{linkText}}
                </a>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .custom-item {
        margin-top: 30px;

        img {
            margin-bottom: 15px;
        }

        strong {
            font-size: 18px;
            margin: 0 0 5px;
            display: block;
        }

        a {
            margin-top: 10px;
        }
    }
</style>

<script>
    export default {
        props: {
            image: String,

            headline1: String,
            description1: String,

            headline2: String,
            description2: String,

            headline3: String,
            description3: String,

            linkUrl: String,
            linkText: String,
        },
    }
</script>