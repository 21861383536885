const BaseService = require('@/services/BaseService')

const namespace = 'stream/custom-item'

const find = (id, options, cb, errorCb) => {
    BaseService.get(namespace + '/' + id, options, cb, errorCb)
}

const create = (options, cb, errorCb) => {
    BaseService.post(namespace, options, cb, errorCb)
}

const createImage = (options, cb, errorCb) => {
    BaseService.post(namespace + '/image', options, cb, errorCb)
}

export {
    find,
    create,
    createImage,
}
