<template>
    <div class="create-custom-item container-fluid">
        <h3>Opret en nyhed</h3>
        <p class="text-muted">Opret en nyhed i brugernes nyhedsfeed</p>

        <hr>

        <template v-if="!published">
            <div class="row">
                <div class="col-lg-8">
                    <template v-if="!headline || !description">
                        <strong>Udfyld overskrift og beskrivelse</strong>

                        <div class="alert alert-success">
                            Start med at udfylde overskrift og beskrivelse for nyheden <i class="fa fa-arrow-right"></i>
                        </div>
                    </template>

                    <template v-if="headline || description">
                        <strong>Preview</strong>

                        <card icon="pe-7s-speaker" :headline="headline" :description="description">
                            <hr>
                            <custom-item :image="imageFullPath" :headline1="subHeadline1" :description1="subDescription1" :headline2="subHeadline2" :description2="subDescription2" :headline3="subHeadline3" :description3="subDescription3" :linkUrl="linkUrl" :linkText="linkText"></custom-item>
                        </card>
                    </template>
                </div>

                <div class="col-lg-4">
                    <strong>Overskrift og beskrivelse i toppen af nyheden *</strong>
                    <input type="text" v-model="headline" placeholder="Overskrift (f.eks. Webinar)" class="form-control">
                    <input type="text" v-model="description" placeholder="Beskrivelse (f.eks. Tilmeld dig kommende webinar om SEO)" class="form-control">

                    <br>

                    <template v-if="headline && description">
                        <div>
                            <strong>Billede</strong>
                            <div class="image-upload-container">
                                <upload-image @upload-success="setImage" @reset="resetImage"></upload-image>
                            </div>
                        </div>

                        <br>

                        <strong>Tekstblok</strong>
                        <input type="text" v-model="subHeadline1" placeholder="Overskrift" class="form-control">
                        <textarea v-model="subDescription1" placeholder="Tekst" class="form-control"></textarea>

                        <br>

                        <strong>Tekstblok</strong>
                        <input type="text" v-model="subHeadline2" placeholder="Overskrift" class="form-control">
                        <textarea v-model="subDescription2" placeholder="Tekst" class="form-control"></textarea>

                        <br>

                        <strong>Tekstblok</strong>
                        <input type="text" v-model="subHeadline3" placeholder="Overskrift" class="form-control">
                        <textarea v-model="subDescription3" placeholder="Tekst" class="form-control"></textarea>

                        <br>

                        <strong>Link</strong>
                        <input type="text" v-model="linkText" placeholder="Tekst på knap" class="form-control">
                        <input type="text" v-model="linkUrl" placeholder="Link (f.eks. http://spotonmarketing.dk)" class="form-control">

                        <br>

                        <strong>Modtagere</strong>
                        <div v-for="organization in organizations" v-if="organizations">
                            <input type="checkbox" v-model="selectedOrganizations" :value="organization.id">
                            <label :for="organization.id">
                                {{organization.title}}
                                <span class="text-muted" v-if="organization.title === 'Premium plus'">(Kunder hos SpotOn Marketing)</span>
                            </label>
                        </div>
                        <i class="fa fa-circle-o-notch fa-spin" v-else></i>

                        <br>

                        <a class="btn btn-danger btn-fill" @click="publish">Publicér nyhed</a>

                        <br>
                    </template>
                </div>
            </div>
        </template>

        <template v-else>
            <div class="alert alert-success">
                Tillykke! Din nyhed vil blive publiceret i OP'N i løbet af 10 minutter
            </div>
        </template>
    </div>
</template>

<style lang="scss" scoped>
    .image-upload-container {
        background: white;
        border: 1px solid #dedede;
        border-radius: 4px;
        padding: 15px;
    }

    strong {
        margin-bottom: 5px;
        display: block;
    }
</style>

<script>
    import UploadImage from '@/app/news-feed/components/create/custom-item/UploadImage'
    import CustomItem from '@/app/news-feed/components/feed/content/custom-item/CustomItem'
    import Card from '@/app/shared/components/Card'

    const CustomItemService = require('@/services/stream/CustomItemService')
    const OrganizationService = require('@/services/customers/OrganizationService')

    export default {
        data() {
            return {
                imagePath: '',
                imageFullPath: '',

                headline: '',
                description: '',

                subHeadline1: '',
                subDescription1: '',

                subHeadline2: '',
                subDescription2: '',

                subHeadline3: '',
                subDescription3: '',

                linkUrl: '',
                linkText: '',

                organizations: [],
                selectedOrganizations: [],

                published: false,
            }
        },

        mounted() {
            this.loadOrganizations()
        },

        methods: {
            setImage(args) {
                this.imagePath = args[0]
                this.imageFullPath = args[1]
            },

            resetImage() {
                this.imagePath = ''
                this.imageFullPath = ''
            },

            publish() {
                this.$swal({
                    text: 'Er du sikker på at du vil publicere nyheden ud til OP\'N brugere?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Send nyhed',
                    cancelButtonText: 'Annuller'
                }).then(() => {
                    const body = {
                        image: this.imagePath,

                        headline: this.headline,
                        description: this.description,

                        subHeadline1: this.subHeadline1,
                        subDescription1: this.subDescription1,

                        subHeadline2: this.subHeadline2,
                        subDescription2: this.subDescription2,

                        subHeadline3: this.subHeadline3,
                        subDescription3: this.subDescription3,

                        linkUrl: this.linkUrl,
                        linkText: this.linkText,

                        organizations: this.selectedOrganizations,
                    }

                    CustomItemService.create(body, () => {
                        this.published = true
                    })
                });
            },

            loadOrganizations() {
                OrganizationService.findAll({}, (response) => {
                    const body = response.data

                    this.organizations = body.rows
                })
            }
        },

        components: {
            UploadImage,
            CustomItem,
            Card,
        }
    }
</script>
