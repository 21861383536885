<template>
    <div class="image-upload">
        <template v-if="imageFullPath">
            <img :src="imageFullPath" class="img-responsive">

            <div>
                <a @click="reset">
                    Fjern billede
                </a>
            </div>
        </template>

        <div class="pull-left" v-else>
            <input type="file" @change="uploadImage" v-if="!isPosting">

            <i class="fa fa-circle-o-notch fa-spin" v-else></i>
        </div>

        <div class="clearfix"></div>
    </div>
</template>

<style lang="scss" scoped>
    strong,
    input {
        margin-bottom: 10px;
        display: block;
    }

    img {
        max-height: 100px;
        margin-right: 10px;
        margin-bottom: 10px;
    }
</style>

<script>
    const CustomItemService = require('@/services/stream/CustomItemService')

    export default {
        data() {
            return {
                imageFullPath: null,
                isPosting: false,
            }
        },

        methods: {
            uploadImage(e) {
                this.isPosting = true

                if (!e.target.files[0]) {
                    return
                }

                const formData = new FormData()

                formData.append('image', e.target.files[0])

                CustomItemService.createImage(formData, (response) => {
                    const body = response.data

                    this.imageFullPath = body.imageFullPath

                    this.$emit('upload-success', [body.imagePath, body.imageFullPath])

                    this.isPosting = false
                }, () => {
                    this.isPosting = false
                })
            },

            reset() {
                this.imageFullPath = null

                this.$emit('reset')
            }
        }
    }
</script>
